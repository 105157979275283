@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@import 'font-awesome';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;


/* *************************************
    CUSTOMIZACIONES MAKEBRAND
************************************* */
/*
 * VARS
 */
$color-lightgray: darken($secondary-color, 1%);
$color-mediumgray: lighten($primary-color, 50%);

/*
 * MIXINS
 */
$breakpoint360: 22.5em;
$breakpoint600: 37.5em;
$breakpoint640: 40em;
$breakpoint768: 48em;
$breakpoint1024: 64em;

$landscape: landscape;
$portrait: portrait;

@mixin bp10 {
  @media screen and (min-width: #{$breakpoint360}) and (orientation: #{$portrait}) {
    @content;
  }
}
@mixin bp20 {
  @media screen and (min-width: #{$breakpoint600}) and (orientation: #{$portrait}) {
    @content;
  }
}
@mixin bp30 {
  @media screen and (max-width: #{$breakpoint640}) and (orientation: #{$landscape}) {
    @content;
  }
}
@mixin bp33 {
  @media screen and (max-width: #{$breakpoint768}) {
    @content;
  }
}
@mixin bp34 {
  @media screen and (min-width: #{$breakpoint768}) {
    @content;
  }
}
@mixin bp35 {
  @media screen and (min-width: #{$breakpoint768}) and (orientation: #{$portrait}) {
    @content;
  }
}
@mixin bp40 {
  @media screen and (min-width: #{$breakpoint1024}) and (orientation: #{$landscape}) {
    @content;
  }
}
@mixin bp50 {
  @media screen and (min-width: #{$breakpoint1024}) and (orientation: #{$portrait}) {
    @content;
  }
}
@mixin bp51 {
  @media screen and (min-width: #{$breakpoint1024}) {
    @content;
  }
}
@mixin bp60 {
  @media screen and (min-width: #{$breakpoint1024 + 0.1em}) {
    @content;
  }
}

/*
 * GLOBAL
 */
body {
    text-align: justify;
}

/* Typography */
.uppercase {
    text-transform: uppercase;
}
.text-center {
    text-align: center;
}

/* Padding and Margins */
.wrapper {
    @media screen and (max-width: 30em) and (orientation: portrait) {
        padding: 0 1rem;
    }
    @media screen and (max-width: 30em) and (orientation: landscape) {
        padding: 0 3rem;
    }
    @media screen and (min-width: 30.1em) and (max-width: 64em) {
        padding: 0 3rem;
    }
}

/* Buttons */
.button {
    &.hollow.primary {
        border: 2px solid $primary-color;
        font-family: 'bebas_neuebold', sans-serif;
        &:hover {
            background-color: $primary-color;
            color: $secondary-color;
        }
    }
    &.hollow.primary.soydarks {
        background-color: rgba(0,0,0,0.5);
        border-color: $secondary-color;
        border-style: solid;
        border-width: 2px;
        color: $secondary-color;
        margin-bottom: 0;
        position: relative;
        text-shadow: none;
        z-index: 2;
        &:hover {
            background-color: $secondary-color;
            color: $primary-color !important;
        }
        @include bp20 {
            font-size: 1.75rem;
        }
    }
}
.button:hover, .button:focus {
    text-shadow: 0 0 30px rgba(0,0,0,0.5), 0 0 5px rgba(0,0,0,0.25);
}

/* Icons */
.isotipo {
    display: inline;
    float: left;
    height: auto;
    margin-right: 1rem;
    width: 5rem;
}
.servicio {

    margin: 0 auto;
    
    .mb-box {
        border-color: $primary-color;
        border: 2px solid;
        border-radius: 25px;
        display: table;
        height: 150px;
        margin: 0 auto 2rem;
        padding: 30px;
        position: relative;
        width: 150px;

        h3 {
            font-family: "geomanistregular",sans-serif;
            font-size: 0.9rem;
            line-height: 1;
            left: 0;
            padding: 0 1rem;
            position: absolute;
            text-align: center;
            top: 6.5rem;
            width: 100%;
        }
    }
}
a.servicio:hover {
    .mb-box {
        border-color: $secondary-color;
        background-color: $primary-color;
        border-radius: 25px;
        color: $secondary-color;
    }
}
.bg-color-black {
    .servicio {
        .mb-box {
            border-color: $secondary-color;
        }
    }
    .servicio:hover {
        .mb-box {
            border-color: $primary-color;
            background-color: $secondary-color;
        }
    }
}
.mb-icon {
    background-image: url("../img/ui/mb-icons.svg");
    background-size: 200% auto;
    display: table-cell;
    margin: 0 auto;
    overflow: hidden;
    vertical-align: middle;
}
.mb-empresarial {
    .mb-icon {
        background-position: 0 0;
    }
    &:hover {
        .mb-icon {
            background-position: -100% 0;
        }
    }
}
.mb-politica {
    .mb-icon {
        background-position: 0 -100%;
    }
    &:hover {
        .mb-icon {
            background-position: -100% -100%;
        }
    }
}
.mb-personal {
    .mb-icon {
        background-position: 0 -200%;
    }
    &:hover {
        .mb-icon {
            background-position: -100% -200%;
        }
    }
}
.mb-cursos {
    .mb-icon {
        background-position: 0 -300%;
    }
    &:hover {
        .mb-icon {
            background-position: -100% -300%;
        }
    }
}
.use-mini-icons {
    .mb-icon {
        display: block;
        height: 65px;
        width: 65px;
    }
    .curso-1 {
        .mb-icon {
            background-position: -100% -400%;
        }
        /*&:hover {
            .mb-icon {
                background-position: 0 -400%;
            }
        }*/
    }
    .curso-2 {
        .mb-icon {
            background-position: -100% -500%;
        }
        /*&:hover {
            .mb-icon {
                background-position: 0 -500%;
            }
        }*/
    }
    .curso-3 {
        .mb-icon {
            background-position: -100% -600%;
        }
        /*&:hover {
            .mb-icon {
                background-position: 0 -600%;
            }
        }*/
    }
    .curso-4 {
        .mb-icon {
            background-position: -100% -700%;
        }
        /*&:hover {
            .mb-icon {
                background-position: 0 -700%;
            }
        }*/
    }
    .curso-5 {
        .mb-icon {
            background-position: -100% -800%;
        }
        /*&:hover {
            .mb-icon {
                background-position: 0 -800%;
            }
        }*/
    }
    .curso-6 {
        .mb-icon {
            background-position: -100% -900%;
        }
        /*&:hover {
            .mb-icon {
                background-position: 0 -900%;
            }
        }*/
    }
}

/* Social media */
#social-media {
    background-color: rgba(0,0,0,0.5);
    border-top: 1px solid darken($secondary-color, 50%);
    display: table;
    margin: 0;
    left: 0;
    list-style: none outside;
    bottom: 0;
    position: fixed;
    width: 100%;
    z-index: 10;

    @media screen and (min-width: 22.5em) and (orientation: landscape) {
        border-right: 1px solid darken($secondary-color, 50%);
        border-bottom: 1px solid darken($secondary-color, 50%);
        bottom: auto;
        position: fixed;
        top: 19%;
        width: auto;
    }

    li {
        display: table-cell;
        @media screen and (min-width: 22.5em) and (orientation: landscape) {
            display: block;
        }
        @include breakpoint(xlarge) {
            display: block;
        }
    }
    a {
        color: $secondary-color;
        display: block;
        font-size: 1.5rem;
        margin: 0.5rem;
        opacity: 0.8;
        position: relative;
        text-align: center;
        top: 0;
        @media screen and (max-width: 40em) and (orientation: landscape) {
            font-size: 1.15rem;
        }
        &:hover {
            opacity: 1;
        }
        &:active {
            opacity: 0.66;
            top: 1px;
        }
    }
    @include breakpoint(xlarge) {
        border-right: 1px solid darken($secondary-color, 50%);
        border-bottom: 1px solid darken($secondary-color, 50%);
        bottom: auto;
        position: fixed;
        top: 10%;
        width: auto;
    }

}

/* Colores y cajas */
.bg-color-black {
    background-color: rgb(11, 11, 11);
    section, article, h1, h2, h3, h4, h5, h6, p, ol, li, ul, table, td, tr, th, i, span {
        color: $secondary-color;
    }
    span {
        &::after {
            border-color: $secondary-color;
        }
    }
}
.bg-color-lightgray {
    background-color: darken($secondary-color, 1%);
}

/* Headers */
.double-heading {
    h2 {
        color: lighten($primary-color, 50%);
        font-size: 1.5rem;
        line-height: 1;
        margin: 7rem 0 0.25rem 0;
        text-align: left;
    }
    h3 {
        display: inline-block;
        font-size: 3rem;
        line-height: 0.88;
        margin-bottom: 2rem;
        padding: 0 1rem 0 0;
        position: relative;
        z-index: 2;
        @include breakpoint(medium) {
            font-size: 4rem;
        }
    }
    header {
        position: relative;
        &::after {
            border-bottom: 2px solid;
            bottom: 2.4rem;
            content: "";
            right: 0;
            position: absolute;
            width: 50%;
            z-index: 1;
        }
    }
}

/* Forms */
.required-legend {
    color: $color-mediumgray;
    display: block;
    font-size: 0.75rem;
    font-style: italic;
    text-align: right;
}
.required {
    color: $color-mediumgray;
}
[type="text"], [type="password"], [type="date"], [type="datetime"], [type="datetime-local"], [type="month"], [type="week"], [type="email"], [type="number"], [type="search"], [type="tel"], [type="time"], [type="url"], [type="color"], textarea, select {
    border-left: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    border-right: none;
    border-bottom: none;
    box-shadow: none;
}
textarea {
    height: 10rem;
}

/*
 * SITE NAV
 */
.home {
    .menu-toggle {
        &:hover {
            color: $secondary-color;
            .linea {
                background-color: $secondary-color;
            }
        }
        .toggle-legend {
            color: darken($secondary-color, 22%);
        }
        .linea {
            background-color: darken($secondary-color, 22%);
        }
    }
}
#site-navigation {

    background-color: rgba(0, 0, 0, 0.91);
    border: 1px solid darken($secondary-color, 15%);
    box-sizing: border-box;
    display: none;
    font-size: 1rem;
    padding: 2.75rem 2.25rem 2.25rem 2.25rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: 17rem;
    z-index: 3;

    ul {
        list-style: none outside;
        margin: 1.5rem 0 0;
        text-align: left;
    }
    li {
        margin: 0.75rem 0;
    }
    a {
        color: darken($secondary-color, 25%);
        display: block;
        font-family: 'bebas_neuebold', sans-serif;
        font-size: 1.25rem;
        line-height: 1;
        margin: 0.2rem 0;
        &.nav-active, &:hover {
            color: $secondary-color;
            position: relative;
        }
        &.nav-active {
            border-left: 5px solid white;
            left: -1rem;
            padding-left: 0.7rem;
        }
    }
}
.menu-toggle {
    cursor: pointer;
    display: block;
    font-size: 0.9rem;
    position: absolute;
    right: 3rem;
    text-align: right;
    text-transform: uppercase;
    top: 2rem;
    z-index: 4;
    .burger {
        position: absolute;
        right: -13px;
        top: 0;
    }
    .toggle-legend {
        color: $primary-color;
        padding-right: 22px;
    }
    .cerrar {
        opacity: 0;
        -ms-transform: translate(20px, 0);
        -webkit-transform: translate(20px, 0);
        transform: translate(20px, 0);
        animation-name: cerrar;
        animation-duration: 2s;
    }
    .menu-legend {
        @media screen and (max-width: 30em) {
            display: none;
        }
    }
    .linea {
        background-color: $primary-color;
        display: block;
        height: 3px;
        margin-bottom: 6px;
        position: relative;
        width: 25px;
    }
    .linea-1 {
        animation-name: linea1Burger;
        animation-duration: 1s;
        top: 0;
    }
    .linea-2 {
        animation-name: linea2Burger;
        animation-duration: 1s;
    }
    .linea-3 {
        animation-name: linea3Burger;
        animation-duration: 0.5s;
        opacity: 1;
        width: 25px;
    }
    &.toggle-close {

        .toggle-legend {
            color: darken($secondary-color, 22%);
        }
        .linea {
            background-color: darken($secondary-color, 22%);
        }
        .linea-1 {
            -ms-transform: rotate(45deg); /* IE 9 */
            -webkit-transform: rotate(45deg); /* Safari */
            transform: rotate(45deg);
            top: 9px;
            width: 18px;
            animation-name: linea1Tachecito;
            animation-duration: 1s;
        }
        .linea-2 {
            -ms-transform: rotate(-45deg); /* IE 9 */
            -webkit-transform: rotate(-45deg); /* Safari */
            transform: rotate(-45deg);
            width: 18px;
            animation-name: linea2Tachecito;
            animation-duration: 1s;
        }
        .linea-3 {
            opacity: 0;
            animation-name: linea3Tachecito;
            animation-duration: 1s;
            width: 0;
        }
        .cerrar {
            opacity: 1;
        }
    }
}
@keyframes cerrar {
    0% {
        -ms-transform: translate(20px, 0);
        -webkit-transform: translate(20px, 0);
        transform: translate(20px, 0);
        opacity: 0;
    }
    100% {
        -ms-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        opacity: 1;
    }
}
@keyframes linea1Burger {
    0% {
        -ms-transform: rotate(45deg); /* IE 9 */
        -webkit-transform: rotate(45deg); /* Safari */
        transform: rotate(45deg);
        top: 9px;
    }
    100% {
        -ms-transform: rotate(0); /* IE 9 */
        -webkit-transform: rotate(0); /* Safari */
        transform: rotate(0);
        top: 0;
    }
}

@keyframes linea2Burger {
    0% {
        -ms-transform: rotate(-45deg); /* IE 9 */
        -webkit-transform: rotate(-45deg); /* Safari */
        transform: rotate(-45deg);
    }
    100% {
        -ms-transform: rotate(0); /* IE 9 */
        -webkit-transform: rotate(0); /* Safari */
        transform: rotate(0);
    }
}

@keyframes linea3Burger {
    0% {
        opacity: 0;
        width: 0;
    }
    100% {
        opacity: 1;
        width: 25px;
    }
}

@keyframes linea1Tachecito {
    0% {
        -ms-transform: rotate(0); /* IE 9 */
        -webkit-transform: rotate(0); /* Safari */
        transform: rotate(0);
    }
    100% {
        -ms-transform: rotate(45deg); /* IE 9 */
        -webkit-transform: rotate(45deg); /* Safari */
        transform: rotate(45deg);
    }
}

@keyframes linea2Tachecito {
    0% {
        -ms-transform: rotate(0); /* IE 9 */
        -webkit-transform: rotate(0); /* Safari */
        transform: rotate(0);
    }
    100% {
        -ms-transform: rotate(-45deg); /* IE 9 */
        -webkit-transform: rotate(-45deg); /* Safari */
        transform: rotate(-45deg);
    }
}

@keyframes linea3Tachecito {
    0% {
        opacity: 1;
        width: 25px;
    }
    100% {
        opacity: 0;
        width: 0;
    }
}

/*
 * HEADER + HOMEPAGE SLIDESHOW
 */
#intro {
    background-color: $primary-color;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;
    .row, .columns {
        height: 100%;
    }
}

.home {
    .site-header {
        left: 0;
        position: absolute;
        top: 0;
        z-index: 2;
    }
}

.site-header {
    text-align: center;
    width: 100%;
}

.home {
    .site-branding {

        margin-top: 0;
        margin-bottom: 0;
        position: relative;
        top: 1.5rem;
        z-index: 2;

        h1, p {
            color: $secondary-color;
        }

        @include bp10 {
            top: 4rem;
        }
        @include bp20 {
            top: 6rem;
        }
        @include bp30 {
            top: 0.9rem;
        }
        @include bp35 {
            top: 4.5rem;
        }
        @include bp40 {
            top: 4.5rem;
        }
        @include bp50 {
            top: 6rem;
        }
        @include bp60 {
            top: 6rem;
        }
    }
}

.site-branding {
    
    margin-bottom: 2.5rem;
    margin-top: 2.5rem;
    text-align: center;

    img {

        display: block;
        height: auto;
        margin: 0 auto;
        width: 4.33rem;

        @include bp10 {
            width: 5rem;
        }
        @include bp20 {
            width: 5rem;
        }
        @include bp30 {
            width: 4.33rem;
        }
        @include bp35 {
            width: 6rem;
        }
        @include bp40 {
            width: 6rem;
        }
        @include bp50 {
            width: 6rem;
        }
        @include bp60 {
            width: 6rem;
        }
    }

    h1 {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        display: inline-block;
        font-family: 'geomanistregular', sans-serif;
        font-size: 1.75rem;
        line-height: 1;
        margin: 6px 0;
        /* text-shadow: 0 10px 300px rgba(0,0,0,0.8), 0 20px 200px rgba(0,0,0,0.4), 0 30px 100px rgba(0,0,0,0.2);
        commented out this line due to an iOS bug */
        text-transform: lowercase;

        @include bp10 {
            font-size: 2rem;
        }
        @include bp20 {
            font-size: 2rem;
        }
        @include bp30 {
            font-size: 1.75rem;
        }
        @include bp35 {
            font-size: 2.5rem;
        }
        @include bp40 {
            font-size: 2.5rem;
        }
        @include bp50 {
            font-size: 2.5rem;
        }
        @include bp60 {
            font-size: 2.5rem;
        }
    }
    p {
        display: block;
        font-size: 0.66rem;
        line-height: 0.8;
        margin: 0;
        text-align: center;

        @include bp10 {
            font-size: 0.75rem;
        }
        @include bp20 {
            font-size: 0.75rem;
        }
        @include bp30 {
            font-size: 0.66rem;
        }
        @include bp35 {
            font-size: 0.9rem;
        }
        @include bp40 {
            font-size: 0.9rem;
        }
        @include bp50 {
            font-size: 0.9rem;
        }
        @include bp60 {
            font-size: 0.9rem;
        }
    }
    .site-description {

        display: none;
        line-height: 2;

        @include bp35 {
            display: block;
            font-size: 0.66rem;
        }
        @include bp40 {
            display: block;
            font-size: 0.66rem;
        }
        @include bp50 {
            display: block;
            font-size: 0.66rem;
        }
        @include bp60 {
            display: block;
            font-size: 0.66rem;
        }

    }
    .bullet {
        font-size: 0.5rem;
        padding: 0 5px;
        position: relative;
        top: -1px;
    }
}

.slide {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 50px 250px rgba(0,0,0,0.8) inset, 0 100px 250px rgba(0,0,0,0.8) inset;
    display: table !important;
    height: 100%;
    width: 100%;
}

.cycle-slideshow {

    width: 100%;
    z-index: 1;

    article {
        background-color: rgba(0,0,0,0.33);
        display: table-cell;
        text-align: center;
        width: 100%;

        @media screen and (min-width: 46.750em) {
            display: table-cell;
            vertical-align: middle;
            width: 100%;
        }
    }
    h2 {
        
        color: $secondary-color;
        font-size: 3.25rem;
        line-height: 0.88;
        margin-bottom: 2rem;
        padding-top: 10.66rem;
        text-shadow: 0 0 40px rgba(0,0,0,0.25), 0 0 5px rgba(0,0,0,0.1);
        width: 100%;

        @include bp10 {
            font-size: 4rem;
            padding-top: 15rem;
        }
        @include bp20 {
            font-size: 5rem;
            padding-top: 20rem;
        }
        @include bp30 {
            font-size: 3rem;
            padding-top: 9.5rem;
        }
        @include bp34 {
            font-size: 3rem;
            padding-top: 8.5rem;
        }
        @include bp35 {
            font-size: 6rem;
            padding-top: 5rem;
        }
        @include bp40 {
            font-size: 5rem;
            padding-top: 13rem;
        }
        @include bp50 {
            font-size: 9rem;
        }
        @include bp60 {
            font-size: 6rem;
            padding-top: 8rem;
        }
        @include breakpoint(xxlarge) {
            font-size: 7rem;
            padding-top: 7rem;
        }

        br {
            @include breakpoint(landscape) {
                display: none;
            }
            @include breakpoint(large) {
                display: none;
            }
        }
    }
    .servicio-desc {
        color: $secondary-color;
        display: none;
        margin: 0 0 3rem 0;
        padding: 0 3rem;
        position: relative;
        text-shadow: 0 0 50px rgba(0,0,0,0.8), 0 0 25px rgba(0,0,0,0.2), 0 0 5px rgba(0,0,0,0.2);
        z-index: 1;
        @media screen and (min-width: 22.5em) and (max-width: 40em) and (orientation: landscape) {
            display: none;
        }
        @media screen and (min-width: 37.5em) and (orientation: portrait) {
            display: block;
            font-size: 1.15rem;
            line-height: 1.25;
            margin: 0 3.5rem 3.5rem;
        }
        @media screen and (min-width: 40em) and (orientation: portrait) {
            display: block;
            font-size: 1.15rem;
        }
        @media screen and (min-width: 64em) and (orientation: landscape) {
            display: block;
            font-size: 1.15rem;
        }
    }

}

#prev, #next {
    background-size: 100% auto;
    background-repeat: no-repeat;
    font-size: 32px;
    cursor: pointer;
    height: 40px;
    opacity: 0.2;
    position: absolute;
    width: 15px;
    z-index: 2;
    @include breakpoint(portrait) {
        top: 44.5%;
    }
    @include breakpoint(landscape) {
        top: 48%;
    }
    &:hover {
        opacity: 1;
    }
    &:active {
        opacity: 0.66;
        margin-top: 1px;
    }
    @include breakpoint(xlarge) {
        display: block;
        height: 60px;
        width: 30px;
    }
}
#prev {
    background-image: url("../img/ui/chevron-left.svg");
    left: 5%;
    @include breakpoint(landscape) {
        left: 9%;
    }
}
#next {
    background-image: url("../img/ui/chevron-right.svg");
    right: 5%;
    @include breakpoint(landscape) {
        right: 9%;
    }
}

#slide-empresarial {
    background-image: url("../img/imagen-empresarial-slide.jpg");
}
#slide-politica {
    background-image: url("../img/imagen-politica.jpg");
    display: none;
}
#slide-personal {
    background-image: url("../img/imagen-personal.jpg");
    display: none;
}
#slide-cursos {
    background-image: url("../img/cursos-slide.jpg");
    display: none;
}

@include breakpoint(xlarge) {
    .animar-1, .animar-2, .animar-3, .animar-4 {
        opacity: 0;
        position: relative;
    }
    #slide-empresarial {
        .animar-1 {
            left: -100px;
            top: -200px;
        }
        .animar-2 {
            bottom: -200px;
            right: -100px;
        }
    }
    #slide-politica {
        .animar-1 {
            left: 0;
            top: -200px;
        }
        .animar-2 {
            bottom: -200px;
            right: 0;
        }
    }
    #slide-personal {
        .animar-1 {
            right: -200px;
        }
        .animar-2 {
            left: -200px;
        }
    }
    #slide-cursos {
        .animar-1 {
            right: -100px;
            top: -200px;
        }
        .animar-3 {
            left: -100px;
            bottom: -200px;
        }
    }
}

#progress {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 0;
    background: #CCC;
    z-index: 500;
}

/*
 * SECTIONS
 */
 .home {
    .special-section {
        p {
            margin: 0 0 4rem 0;

            @include breakpoint(large) {
                margin: 0 0 7rem 0;
            }
        }
    }
 }
 .special-section {

    text-align: center;

    .section-heading {
        font-size: 2.5rem;
        line-height: 1;
        margin: 4rem 0;
        position: relative;

        @include breakpoint(large) {
            font-size: 3.5rem;
            margin: 6.5rem 0 4rem 0;
        }

        @include breakpoint(xxlarge) {
            font-size: 4.5rem;
        }

        &::after {
            border-top: 7px solid;
            content: "";
            display: block;
            height: 7px;
            margin: 0 auto;
            width: 4rem;
        }
    }
    section {
        text-align: justify;
    }
}
.basic-page {
    .section-heading {
        font-size: 4rem;
        margin: 0 0 2rem 0;
        padding: 2.25rem 0;
    }
    .subheading {
        font-family: "geomanistregular", sans-serif;
        font-size: 1.8rem;
        margin: 0 0 2rem 0;
        text-align: center;
    }
    section {
        margin: 0 auto;
        max-width: 75rem;
    }
}
#page-blog {

    padding-bottom: 10rem;

    article {
        h1, h2, h3, h4, h5, h6 { text-align: left; line-height: 1; }
        h1 { font-size: 4rem; }
        img { margin-bottom: 1rem; }
    }

    .section-heading {
        font-size: 4rem;
        padding: 2.25rem 0;
    }
    .bg-color-black {
        margin-bottom: 2rem;
    }
}

/* Servicios */
#servicios {

    border-bottom: 1px solid darken($secondary-color, 60%);
    border-top: 1px solid darken($secondary-color, 60%);
    
    h3 {
        font-size: 1.75rem;
        line-height: 0.88;
        br {
            display: none;
            @include breakpoint(xlarge) {
                display: none;
            }
        }
    }
    p {
        font-size: 1rem;
        @include breakpoint(medium) {
            font-size: 0.9rem;
        }
    }
    a {
        display: inline-block;
    }
    a:hover {

        article, p {
            color: lighten($secondary-color, 20%);
        }
    }
}

/* Proyecto destacado */
#destacado {

    padding-top: 15rem;

    @include breakpoint(medium) {
        padding-top: 0;
    }

    article {
        margin-bottom: 7.5rem;
    }
    
    .expanded {
        position: relative;
    }

    &.double-heading {
        h3 {
            background-color: $color-lightgray;
        }
    }

    p {
        margin: 1rem 0 2rem 0;
    }
}
#destacado-img {
    background-image: url("../img/portafolio/grupo-dento/02.jpg");
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 17rem;
    position: absolute;
    right: 0;
    top: -15rem;
    width: 100%;
    @include breakpoint(medium) {
        background-size: auto 100%;
        top: 0;
        height: 100%;
        width: 46%;
    }
    @include breakpoint(large) {
        background-size: 100% auto;
    }
    @include breakpoint(xlarge) {
        background-size: auto 100%;
    }
}

/* Clientes / Portafolio */
#clientes {
    
    box-sizing: border-box;
    
    * {
        box-sizing: border-box;
    }
    h2, p {
        @media screen and (max-width: 30em) and (orientation: portrait) {
            margin-left: 1rem;
            margin-right: 1rem;
        }
        @media screen and (max-width: 30em) and (orientation: landscape) {
            margin-left: 3rem;
            margin-right: 3rem;
        }
        @media screen and (min-width: 30.1em) and (max-width: 64em) {
            margin-left: 3rem;
            margin-right: 3rem;
        }
    }

}
.portafolio {
    &::after {
        clear: both;
        content: "";
        display: table;
    }
}
.portafolio-expanded {

    list-style: none outside;
    margin: 0 0 4rem 0;

    &::after {
        clear: both;
        content: "";
        display: block;
        height: 30px;
        width: 100%;
    }

    li {
        padding: 0 1px 1px 0;
        width: 100%;
        @include breakpoint(medium) {
            float: left;
            padding: 0 1px 1px 0;
            width: 50%;
        }
        @include breakpoint(large) {
            width: 25%;
        }
    }
    a {
        display: block;
        height: 100%;
        position: relative;
        width: 100%;
    }
    img {
        height: auto;
        position: relative;
        width: 100%;
        z-index: 1;
    }
    i {
        font-size: 0.9rem;
        padding-left: 0.25rem;
    }
    .overlay {
        background-color: rgba(0,0,0,0.75);
        color: $secondary-color;
        display: block;
        height: 100%;
        opacity: 0;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 2;
    }
    .cliente {
        display: block;
        font-size: 1.75rem;
        padding-top: 6.75rem;
    }
    .vermas {
        border: 2px solid white;
        display: inline-block;
        font-size: 1rem;
        margin-top: 1rem;
        padding: 0.25rem 1.25rem;
    }
}
@keyframes overlay {
    0% { top: -100%; }
    100% { top: 0; }
}

/* PROCESO */
#proceso {

    background-color: lighten($primary-color, 20%);
    background-image: url("../img/imagen-empresarial-slide.jpg");
    background-size: cover;
    box-shadow: 0 0 800px rgba(0, 0, 0, 1) inset, 0 0 3000px rgba(0, 0, 0, 0.75) inset;

    h2, p, .button {
        color: $secondary-color;
    }
    h2 {
        margin-bottom: 3rem;
    }
    .section-desc {
        font-size: 1.33rem;
        margin-bottom: 2rem;
    }
    .wrapper {
        background-color: rgba(0,0,0,0.5);
    }
}

/* CONTACTO */
#contacto {
    &.double-heading {
        h3 {
            background-color: white;
        }
    }
    .lead {

        margin-bottom: 7rem;

        &::after {
            clear: both;
            content: "";
            display: table;
        }
    }
    .button {
        width: 100%;
    }
    form {
        margin-bottom: 6rem;
        @include bp60 {
            margin: 7.5rem 0 6rem 0;
        }
    }
    p {
        margin-bottom: 2rem;
    }
}
#map {
    height: 425px;
}

/* SITE FOOTER */
.site-footer {

    position: relative;

    .logo {

        background-color: rgb(11, 11, 11);
        border-radius: 20rem;
        display: block;
        height: 15rem;
        left: 50%;
        margin-left: -7.5rem;
        margin-bottom: -8rem;
        padding: 2rem;
        position: relative;
        text-align: center;
        top: -5rem;
        width: 15rem;

        img {
            width: 4rem;
        }
        h3 {
            font-family: 'geomanistregular', sans-serif;
            font-size: 1.66rem;
            margin-bottom: 0;
            text-transform: lowercase;
        }
        p {
            font-family: 'geomanistregular', sans-serif;
            font-size: 0.6rem;
            line-height: 1;
            text-align: center;
        }
        .bullet {
            font-size: 0.33rem;
            padding: 0 5px;
            position: relative;
            top: -1px;
        }
        .site-description {
            font-size: 0.75rem;
        }
    }

    blockquote {
        border: none;
        box-shadow: none;
        display: none;
        font-family: "bebas_neuebold",sans-serif;
        font-size: 3rem;
        margin-bottom: 3rem;
        position: relative;
        &::before {
            color: #f9f9f9;
            content: "“";
            display: block;
            font-size: 9rem;
            height: 80px;
            left: -2rem;
            line-height: 1;
            position: absolute;
            top: -2rem;
            @include breakpoint(large) {
                left: -4rem;
            }
        }
        &::after {
            color: $secondary-color;
            content: "”";
            display: block;
            font-size: 10rem;
            position: absolute;
            right: -2rem;
            top: 0;
            @include breakpoint(large) {
                right: 0;
            }
        }
        &:first-child {
            display: block;
        }
    }
    p {
        font-size: 3rem;
        line-height: 1;
        text-align: left;
    }
    .author {
        font-family: 'geomanistregular', sans-serif;
        font-size: 1.5rem;
        font-style: italic;
        margin-top: 2rem;
        text-align: right;
    }
    .inline-list {
        border-box: content-box;
        display: block;
        font-size: 0.8rem;
        list-style: outside none none;
        margin: 0;
        padding: 4rem 0 5rem 0;
        text-align: center;
        width: 100%;
        &::before {
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,212121+50,ffffff+100&0+0,1+50,0+100 */
            background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(33,33,33,1) 50%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(33,33,33,1) 50%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(33,33,33,1) 50%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            display: block;
            content: "";
            height: 1px;
            position: relative;
            top: -1rem;
            width: 100%;
        }
        li {
            span {
                display: none;
                opacity: 0.2;
                padding: 0 1rem;
            }
            @include breakpoint(medium) {
                display: inline;
                span {
                    display: inline-block;
                }
            }
        }
        a {
            color: darken($secondary-color, 25%);
            &:hover {
                color: $secondary-color;
                text-decoration: underline;
            }
        }
        &::after {
            clear: both;
            content: "";
            display: block;
        }
    }
}
#mc_embed_signup {
    margin-top: 1.5rem;
    h3 {
        font-size: 1.5rem;
        font-weight: normal;
        text-align: left;
    }
    label {
        color: $secondary-color;
    }
    .button {
        height: 2.4375rem;
        padding: 0;
        width: 100%;
        @include bp51 {
            margin-top: 1.6rem;
        }
    }
}
.basic-page, .minimalist-page {

    padding-bottom: 7rem;

    &#page-contacto {
        padding-bottom: 0;
    }
    
    p {
        margin-bottom: 2rem;
    }
    .mb-box {
        margin: 0 auto 2rem auto;
        max-width: 150px;
        width: 100%;
    }
}
.minimalist-page {
    .section-heading {
        border-bottom: 1px solid;
        border-top: 1px solid;
        margin-bottom: 1rem;
        padding: 1rem 0;
    }
}
.reveal {
    h1, h2, h3, h4, h5, h6 {
        line-height: 1;
        margin-bottom: 2rem;
        text-align: left;
    }
    @include breakpoint(small) {
        padding: 1rem;
    }
    @include breakpoint(medium) {
        padding: 3rem 5rem;
    }
}
.curso-modal {
    .button {
        width: 100%;
    }
    .mb-box {
        float: left;
        height: 140px;
        margin: 0.5rem 1.5rem 0 0;
        width: 140px;
    }
}
.anterior, .siguiente {
    color: darken($secondary-color, 5%);
    font-size: 2rem;
    margin-top: -1rem;
    position: absolute;
    top: 50%;
}
.anterior {
    left: 1.75rem;
}
.siguiente {
    right: 1.75rem;
}
.pasos {
    background-color: #6d6e71;
    border-top: 1px solid $secondary-color;
    color: #FFF;
    display: none;
    padding: 4rem 0;
    ul {
        
        list-style: none outside;
        margin: 0 auto;
        width: 20rem;

        @include bp51 {
            margin: 0 0 0 8rem;
            width: auto;
        }          
    }
    h3 {
        font-size: 4.5rem;
        line-height: 1;
    }
    .paso {
        
        margin-bottom: 2rem;

        @include bp51 {
            float: left;
            font-size: 0.9rem;
            padding-left: 3rem;
            position: relative;
            text-align: justify;
            width: 16rem;
        }
    }
}
.linea-proceso {
    background-color: darken($secondary-color, 5%);
    height: 3px;
    margin-bottom: 2rem;
    margin-top: 2rem;
    position: relative;
    width: 100%;
}
.linea-paso {
    background-color: darken($secondary-color, 5%);
    display: block;
    height: 32px;
    position: absolute;
    top: -17px;
    width: 3px;
}
#linea-1, #linea-2, #linea-3, #linea-4 {
    left: 0;
    opacity: 0;
}
.numero {
    font-family: "bebas_neuebold",sans-serif;
    @include bp51 {
        font-size: 2rem;
        left: 1.333rem;
        position: absolute;
        top: -1.2rem;
    }
}
.paso {
    left: -2rem;
    opacity: 0;
    position: relative;
}
#page-cliente img {
    margin-bottom: 2rem;
}
.labels {
    list-style: none outside;
    margin: 0;
    padding: 0;
    li {
        display: inline;
    }
}
.tags {
    margin-top: 4rem;
}
.tags, .share {
    margin-bottom: 2rem;
    i {
        font-size: 1.25rem;
        padding-left: 0.5rem;
    }
}
.label {
    &:hover {
        background-color: $color-mediumgray;
        color: $secondary-color;
        cursor: pointer;
    }
}
#page-contacto {
    section {
        margin: 2rem 0 6rem 0;
    }
}
#map {
    border-top: 1px solid lighten($primary-color, 80%);
}
.thumbnail {
    margin-bottom: 0.25rem;
}
.column {
    margin-bottom: 1rem;
}
section.portafolio img {
    margin-bottom: 3rem;
}